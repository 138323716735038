import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';

const NikkeGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_guide.png"
            alt="Introduction to the game"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>A beginner guide for NIKKE.</h2>
          <p>
            Last updated: <strong>05/01/2024</strong>
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="About NIKKE" />
              <p>
                Goddess of Victory: NIKKE (or NIKKE: Goddess of Victory as both
                versions appear in the social media) is third-person
                shooter/idle game mix developed by SHIFTUP, the creators of
                Destiny Child.
              </p>
              <p>
                In NIKKE you focus on collecting characters and developing them,
                but what makes the game stand out from other idle games is the
                combat. Instead of the usual isometric turn-based battles that
                are the staple of the idle genre, in NIKKE you take control of
                the characters directly and shoot everything that moves on the
                screen.
              </p>
              <p>
                The best way to explain the combat is to show it, so check the
                below video:
              </p>
              <Row className="video-row">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="FqvhkYzrMT4" />
                </Col>
              </Row>
              <p>
                Still, even if you dislike the style of combat, there exist an
                auto battle mode that while not perfect, allows you to breeze
                through most of the stages.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297253"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Frequently Asked Questions" />
              <h5>Is NIKKE a main or side type of game?</h5>
              <p>
                Definitely side, like most idle games. While the initial few
                days will give you plenty of things to do -{' '}
                <strong>
                  especially considering the fact that the game has no stamina
                  system
                </strong>{' '}
                - you will hit a wall sooner or later and the only way to push
                through it will be to wait for resources to pile up, so you can
                upgrade your characters.
              </p>
              <h5>How long does it take to finish the dailies?</h5>
              <p>
                Around 20 minutes is enough to do everything you need for the
                day.
              </p>
              <h5>What are the rates?</h5>
              <p>
                4% for SSR - the highest rarity - on the generic banner and 2%
                for SSR on Friendship banner.
              </p>
              <h5>Is there a pity system?</h5>
              <p>
                No. It uses the spark system instead - each pull you do gives
                you a Mileage Ticket and after gathering 200 you can exchange
                them for one of the SSR characters available in the shop.
              </p>
              <p>
                Furthermore, the game has a <strong>Wish System</strong> that
                unlocks after you do your first 40 pulls. The Wish System allows
                you to select 5 characters from 3 out of 4 Manufacturers
                (Pilgrims are excluded) that you want to receive and when you
                pull an SSR from that Manufacturer, it will 100% be one of the
                characters on your Wishlist.
              </p>
              <h5>How important are dupes?</h5>
              <p>
                Dupes increase the level cap of your characters. The initial
                level cap is 80 and each dupe increases it by 40 - up to 200. So
                for SSR characters{' '}
                <strong>you only need 3 dupes to reach the level cap</strong>{' '}
                (SR characters are capped at 160 and need 2 dupes to max).
              </p>
              <p>
                Also, if you receive a dupe of a character, it gets converted
                into Shards, so you can't use the multiple copies of the same
                character in one team.
              </p>
              <p>
                Be aware that the game has a Synchronizer system that takes your
                5 highest leveled characters and sets the level of your other
                ones to the lowest level from the 5. So similar how AFK Arena or
                other idle games do it. And what's important, if you place a SSR
                that you didn't receive any dupes for into the Synchronizer, she
                will still be leveled above her level cap.
              </p>
              <p>
                If you want to learn more about the character progression
                system, check our other guide:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={3}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Character progression"
                  link="/nikke/guides/character-progression"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_character.png"
                      alt="Character progression"
                    />
                  }
                />
              </Row>
              <h5>Is the game P2W?</h5>
              <p>
                P2W not really, but the prices of the packs in game are pretty
                expensive.
              </p>
              <h5>Is rerolling a must?</h5>
              <p>
                Yes, because there's a big difference between the best
                characters and the underwhelming ones. Check our Reroll guide if
                you want to learn more:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={3}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Reroll guide"
                  link="/nikke/guides/reroll"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_reroll.png"
                      alt="Reroll guide"
                    />
                  }
                />
              </Row>
              <h5>Is there a tier list?</h5>
              <p>Yes! You can find it here:</p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={3}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Tier list"
                  link="/nikke/tier-list"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_tierlist.png"
                      alt="Tier list"
                    />
                  }
                />
              </Row>
              <h5>Is there PVP?</h5>
              <p>
                Yes! But it's all auto - you pick an enemy to attack and the
                game does its thing.
              </p>
              <h5>How many servers are there?</h5>
              <p>
                Five servers: North America, Europe (dubbed Global), Southeast
                Asia, Korea and Japan.
              </p>
              <h5>Does the game have a PC client?</h5>
              <p>
                Yes! You can download it from the official website for the game.
              </p>
              <h5>Can I play this game on an emulator?</h5>
              <p>
                While in the past you could, emulators are now officially
                banned.
              </p>
            </div>
            <div className="section-scroll" id="section-2">
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297016"
              ></div>
              <SectionHeader title="Early game tips" />
              <p>
                Once you finish the tutorial and do you initial pulls (hopefully
                getting some strong characters), before you continue pushing
                through the story <strong>you need to build your team</strong>.
              </p>
              <p>
                Compared to other idle games, where you can just throw your
                highest rarity characters together, in NIKKE, team building is a
                bit more complicated and you can potentially brick your team if
                you do it wrong - mainly due to how the Burst and Weapon Systems
                works.
              </p>
              <p>
                But have no fear! We created two dedicated guides that will help
                you progress faster through the game:
              </p>
              <ul>
                <li>
                  Weapon guide - it explains the differences between the various
                  weapons available in the game (Assault Rifle, Minigun, SMG,
                  Sniper Rifle, Rocket Launcher and Shotgun) and how the choice
                  of weapon impacts the gameplay,
                </li>
                <li>
                  Team Building guide - it explains the Burst System and gives
                  tips how to build your team. Also, it features a few example
                  teams.
                </li>
              </ul>
              <p>You can find both guides here:</p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={3}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Weapon types"
                  link="/nikke/guides/weapon-types"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_7.png"
                      alt="Weapon types"
                    />
                  }
                />
                <CategoryCard
                  title="Team building"
                  link="/nikke/guides/team-building"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_8.png"
                      alt="Team building"
                    />
                  }
                />
              </Row>
              <p>
                Make sure to use Rapi, the free character everyone obtains, as
                she's the best SR in the game and will carry you pretty far till
                you will be able to obtain better SSR characters that will
                replace her.
              </p>
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="rapi" enablePopover />
              </div>
              <h5>How to progress?</h5>
              <p>
                Once you have your team setup, you can continue progressing
                through the campaign.
              </p>
              <p>
                After you have progressed far enough into Campaign, you will
                soon hit a wall and will no longer be able to clear stages
                easily. As the story stages cannot be replayed except for
                EX-Stages, you will now need to focus on other modes of the
                game.
              </p>
              <p>
                After you unlock the <strong>Recycling room</strong>, you can
                now increase the characters’ passive stats further by investing
                into various cores. Each core will represent a certain class and
                a certain manufacturer. It is recommended for newer players to
                focus on investing into the Attacker Class as that will boost
                your main DPSs’ damage and help you progress further into the
                story.
              </p>
              <p>
                After you unlock <strong>Interception</strong>, you can now farm
                for various tiers of equipment, up to Tier 8 as of CBT2/TT.
                Interception is a boss battle mode where you will fight a
                certain boss and depending on how much damage you have done to
                the boss within a time limit you will be awarded equipment to
                either upgrade your current equipment or obtain new equipment
                for your characters. Interception can be done 3 times per day.
              </p>
              <p>
                After you unlock <strong>the Simulation Room,</strong> you can
                now farm for various skill shards to upgrade your Characters’
                skills. Simulation room stages can be cleared individually once
                per stage daily.
              </p>
              <p>Check our guides for all the available game modes:</p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={3}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Overview"
                  link="/nikke/guides/game-modes"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_modes.png"
                      alt="Game modes"
                    />
                  }
                />
                <CategoryCard
                  title="Campaign"
                  link="/nikke/guides/game-modes-campaign"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_story.png"
                      alt="Campaign"
                    />
                  }
                />
                <CategoryCard
                  title="Simulation Room"
                  link="/nikke/guides/game-modes-simulation"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_sim.png"
                      alt="Simulation Room"
                    />
                  }
                />
                <CategoryCard
                  title="Interception"
                  link="/nikke/guides/game-modes-interception"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_interception.png"
                      alt="Interception"
                    />
                  }
                />
                <CategoryCard
                  title="Tribe Towers"
                  link="/nikke/guides/game-modes-tribe-towers"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_tribe.png"
                      alt="Tribe Towers"
                    />
                  }
                />
                <CategoryCard
                  title="Lost Sector"
                  link="/nikke/guides/game-modes-lost-sector"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_lost.png"
                      alt="Lost Sector"
                    />
                  }
                />
              </Row>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={['section-0', 'section-1', 'section-2']}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">About NIKKE</a>
                </li>
                <li>
                  <a href="#section-1">Frequently Asked Questions</a>
                </li>
                <li>
                  <a href="#section-2">Early game tips</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | NIKKE | Prydwen Institute"
    description="Goddess of Victory: NIKKE beginner guide."
  />
);
